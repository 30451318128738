import React from 'react';

import { connect } from 'react-redux';

import { MiscUtils } from '../../../utils/MiscUtils';
import { PageTypes } from '../../constants/Pages';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { IGame } from '../../models/Game/Game';
import { CategoryPageSEO } from '../../models/PagesData';
import { DeviceType } from '../../services/DeviceDetector';
import { RowGamesSliders } from '../RowGamesSliders/RowGamesSliders';
import styles from './CategoryPageContent.css';
import { AllCategoriesStrategy } from './Strategies/AllCategoriesStrategy';
import { DesktopStrategy } from './Strategies/DesktopStrategy';
import { GroupStrategy } from './Strategies/GroupStrategy';
import { MobileStrategy } from './Strategies/MobileStrategy';

type CategoryPageContentState = {
    strategy: GroupStrategy;
    variation: string;
};

type CategoryPageContentProps = {
    games: IGame[];
    categories: CategoryPageSEO[];
    pageType: PageTypes;
    currentLanguage: string;
    deviceType: DeviceType;
    adFree: boolean;
    variation: string;
    seeAllGames: boolean;
    userFavoritesList: string[];
    categoryPageName: string;
};

class CategoryPageContentBase extends React.PureComponent<CategoryPageContentProps, CategoryPageContentState> {
    containerRef = React.createRef<HTMLDivElement>();

    readonly state = {
        strategy: this.getStrategy(),
        variation: this.props.variation,
    };

    getStrategy(): GroupStrategy {
        const { pageType, deviceType, currentLanguage } = this.props;

        if (pageType !== PageTypes.AllCategories) {
            if (MiscUtils.isServer) {
                switch (deviceType) {
                    case DeviceType.MOBILE:
                        return new MobileStrategy(() => 480, deviceType);
                    case DeviceType.TABLET:
                        return new MobileStrategy(() => 1023, deviceType);
                    case DeviceType.DESKTOP:
                    default:
                        return new DesktopStrategy(() => 1024, deviceType);
                }
            }

            if (!(this.containerRef && this.containerRef.current)) {
                return new DesktopStrategy(() => 1024, deviceType);
            }

            if (window.matchMedia('(min-width: 1024px)').matches) {
                return new DesktopStrategy(() => this.containerRef.current.clientWidth, deviceType);
            }

            return new MobileStrategy(() => this.containerRef.current.clientWidth, deviceType);
        }
        if (pageType === PageTypes.AllCategories) {
            return new AllCategoriesStrategy(currentLanguage);
        }
    }

    updateStrategy() {
        const newStrategy = this.getStrategy();
        this.setState({ strategy: newStrategy });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateStrategy.bind(this));
        this.updateStrategy();
    }

    componentDidUpdate(prevProps: CategoryPageContentProps) {
        if (prevProps.games !== this.props.games) {
            this.updateStrategy();
        }
        if (prevProps.variation !== this.props.variation) {
            this.setState({ variation: this.props.variation });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateStrategy.bind(this));
    }

    render() {
        const { strategy, variation } = this.state;
        const { pageType, games, categories, adFree, seeAllGames, userFavoritesList, categoryPageName } = this.props;
        const isNotAllGamesPageType = pageType !== PageTypes.AllCategories;
        const items = isNotAllGamesPageType
            ? favoritesService.favoritesListSorter(games, userFavoritesList)
            : categories;
        const groups = strategy.buildGroups(items, adFree, variation, seeAllGames);

        return (
            <div ref={this.containerRef} className={styles.contentContainer}>
                {seeAllGames ||
                variation !== 'C' ||
                pageType === PageTypes.AllCategories ||
                categoryPageName !== 'All' ? (
                    groups.map((group, index) => (
                        // Usage index as a key doesn't cause any issues in this case.
                        // Because won't be sorted or edited by user.
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={index}>
                            <div className={styles.gamesContainer}>{group}</div>
                        </React.Fragment>
                    ))
                ) : (
                    <></>
                )}

                {variation === 'C' && !seeAllGames && pageType === PageTypes.Category && categoryPageName === 'All' && (
                    <RowGamesSliders adFree={adFree} />
                )}
            </div>
        );
    }
}

export const CategoryPageContent = connect((state) => ({
    userFavoritesList: state.userFavoritesList,
}))(CategoryPageContentBase);
