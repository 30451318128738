import React from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router';

import styles from './Banner.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../services/Analytics/Analytics';

type BannerType = {
    url: string;
    titleTxt: string;
    subTitleTxt: string;
};

export const Banner = React.memo((props: BannerType) => {
    const { url, titleTxt, subTitleTxt } = props;
    const history = useHistory();

    return (
        <div className={styles.wrapper}>
            <div className={styles.bannerContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.title}>{titleTxt}</div>
                    <div className={styles.subTitle}>{subTitleTxt}</div>
                </div>
                <Button
                    next
                    onClick={() => {
                        Analytics.trackEvent(Analytics.categories.seeAllBannerClick());
                        history.push(url);
                    }}
                    className={classNames(styles.button, styles.white)}
                >
                    <I18nText as="span" keyName="SEE_ALL" />
                </Button>
            </div>
        </div>
    );
});
