import React from 'react';

import classNames from 'classnames';

import styles from './PageBackground.css';

type PageBackgroundProps = {
    isMyProfile?: boolean;
    className?: string;
    topAsset: (left: boolean) => string;
    gameHeaderStyles: React.CSSProperties;
    pageType: string;
};

export const PageBackground = ({ topAsset, gameHeaderStyles, pageType }: PageBackgroundProps) => {
    const leftAsset = topAsset(true);

    return (
        <div className={classNames(styles.headerAssets, styles[pageType])} style={gameHeaderStyles}>
            {leftAsset && (
                <div className={styles.headerGameLeftAsset} style={{ backgroundImage: `url(${leftAsset})` }} />
            )}
        </div>
    );
};
