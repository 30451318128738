import React, { KeyboardEventHandler, useState } from 'react';

import classNames from 'classnames';

import { TabButton } from '../../atoms/Buttons/TabButton/TabButton';
import { i18nTags, I18nText } from '../../atoms/i18nText/i18nText';
import styles from './PageDescription.css';

type PageDescriptionContentType = {
    key: string;
    tabName: string;
    description: JSX.Element | string;
    tabCaptionTag?: i18nTags;
};

type PageDescriptionProps = {
    content: PageDescriptionContentType[];
    className: string;
};

export const PageDescription = ({ content, className }: PageDescriptionProps) => {
    const [activeTab, setActiveTab] = useState<string>(content[0].key || '');

    const handleArrowClick: KeyboardEventHandler<HTMLDivElement> = (event) => {
        /** We use preventDefault method for preventing scrolling the page with arrow buttons usage.
         * If we call it outside of conditions Tab click behaviour will be broken.
         * */
        const { key } = event;

        if (key === 'ArrowLeft') {
            event.preventDefault();
            setActiveTab(content[0].key);
            document.getElementById(`${content[0].key}-tab`)?.focus();
        } else if (key === 'ArrowRight') {
            event.preventDefault();
            setActiveTab(content[1].key);
            document.getElementById(`${content[1].key}-tab`)?.focus();
        }
    };

    if (!content.length) {
        return null;
    }

    return (
        <div className={styles.descriptionBlock}>
            <div
                className={styles.tabHeader}
                role="tablist"
                onKeyDown={content.length > 1 ? handleArrowClick : undefined}
            >
                {content.map(({ key, tabName, tabCaptionTag }, index) => (
                    <TabButton
                        key={index}
                        tabKey={key}
                        active={activeTab === key}
                        onClick={() => setActiveTab(key)}
                        className={className}
                    >
                        <I18nText as={tabCaptionTag} keyName={tabName} />
                    </TabButton>
                ))}
            </div>
            <div className={styles.descriptionText}>
                {content.map(({ key, description }) => {
                    const attributes = {
                        key: key,
                        className: classNames(
                            styles.textItem,
                            className === 'categoryPageTab' && styles.categoryPageText,
                            className === 'gamePageTab' && styles.gamePageText,
                            styles[`${key}-tabpanel`],
                            activeTab === key ? styles.activeTextItem : ''
                        ),
                        role: 'tabpanel',
                        id: `${key}-tabpanel`,
                        'aria-labelledby': `${key}-tab`,
                        tabIndex: 0,
                        dangerouslySetInnerHTML: typeof description === 'string' ? { __html: description } : null,
                        children: React.isValidElement(description) ? description : null,
                    };

                    return <div {...attributes} />;
                })}
            </div>
        </div>
    );
};
