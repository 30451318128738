import React from 'react';

import classNames from 'classnames';

import styles from './CategorySideBar.css';
import { StringUtils } from '../../../utils/StringUtils';
import { TextButton } from '../../atoms/Buttons/TextButton/TextButton';
import { NavigationMenuItem } from '../../constants/Menu';
import { PageTypes } from '../../constants/Pages';
import { IGame } from '../../models/Game/Game';

export type CategorySideBarProps = {
    className?: string;
    activeCategorySlug: string;
    sideBarMenuItems: NavigationMenuItem[];
    pageType: PageTypes;
    currentLang: string;
    games: IGame[];
    onMenuItemClick?: (link: string, categorySlug: string) => void;
};

type CategorySideBarBaseProps = {
    innerRef?: any;
} & CategorySideBarProps;

type CategorySideBarBaseState = {
    activeItem: NavigationMenuItem;
    restItems: NavigationMenuItem[];
};

class CategorySideBarBase extends React.PureComponent<CategorySideBarBaseProps, CategorySideBarBaseState> {
    readonly state = {
        activeItem: null,
        restItems: [],
    };

    constructor(props) {
        super(props);

        if (this.props.pageType === PageTypes.Category) {
            this.state.activeItem = this.props.sideBarMenuItems.find((item) =>
                StringUtils.equalIgnoreCase(item.slug, this.props.activeCategorySlug)
            );
            this.state.restItems = this.state.activeItem
                ? this.props.sideBarMenuItems.filter(
                      (item) => !StringUtils.equalIgnoreCase(item.slug, this.state.activeItem.slug)
                  )
                : this.props.sideBarMenuItems;
        } else if (this.props.pageType === PageTypes.AllCategories) {
            this.state.restItems = this.props.sideBarMenuItems;
        }
    }

    updateData() {
        const activeItem: NavigationMenuItem = this.props.sideBarMenuItems.find((item) =>
            StringUtils.equalIgnoreCase(item.slug, this.props.activeCategorySlug)
        );
        this.setState({
            activeItem,
            restItems: activeItem
                ? this.props.sideBarMenuItems.filter((item) => !StringUtils.equalIgnoreCase(item.slug, activeItem.slug))
                : this.props.sideBarMenuItems,
        });
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps: CategorySideBarBaseProps) {
        if (this.props.activeCategorySlug !== prevProps.activeCategorySlug) {
            this.updateData();
        }
    }

    render() {
        const { className, innerRef, onMenuItemClick } = this.props;
        const { activeItem, restItems } = this.state;

        const menuSelectedElement = activeItem ? (
            <li key={activeItem.key} data-element-description="left-sidebar-item" aria-current="true">
                <span className={classNames(styles.menuItemText, styles.activeMenuItem)}>
                    {activeItem.sidebarCaption}
                </span>
            </li>
        ) : null;

        const menuElement = (item: NavigationMenuItem, index: number) => (
            <li key={`${item.key}-${index}`} data-element-description="left-sidebar-item" aria-current="false">
                <TextButton
                    caption={item.sidebarCaption}
                    onClick={() => {
                        onMenuItemClick?.(item.url, item.slug);
                    }}
                    buttonClassName={styles.menuItemButton}
                    textClassName={styles.menuItemText}
                />
            </li>
        );

        return (
            <div ref={innerRef} className={classNames(styles.sideBar, className)}>
                <ul>{menuSelectedElement}</ul>
                <ul className={styles.scrollableContainer}>{restItems.map(menuElement)}</ul>
            </div>
        );
    }
}

export const CategorySideBar = React.forwardRef<HTMLDivElement, CategorySideBarProps>(
    (
        {
            className,
            activeCategorySlug,
            sideBarMenuItems,
            pageType,
            currentLang,
            games,
            onMenuItemClick,
        }: CategorySideBarProps,
        ref
    ) => {
        return (
            <CategorySideBarBase
                innerRef={ref}
                className={className}
                activeCategorySlug={activeCategorySlug}
                sideBarMenuItems={sideBarMenuItems}
                pageType={pageType}
                currentLang={currentLang}
                games={games}
                onMenuItemClick={onMenuItemClick}
            />
        );
    }
);
