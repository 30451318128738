import React from 'react';

import classNames from 'classnames';

import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { CategoryPageSEO } from '../../../models/PagesData';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { CategoryCard } from '../../../molecules/Cards/CategoryCard/CategoryCard';
import styles from '../CategoryPageContent.css';
import { GroupStrategy } from './GroupStrategy';

export class AllCategoriesStrategy implements GroupStrategy {
    private currentLang: string;

    constructor(lang: string) {
        this.currentLang = lang;
    }

    buildGroups(categories: CategoryPageSEO[], adFree: boolean): React.ReactFragment[] {
        const result = [];
        result.push(
            <>
                {!adFree && (
                    <div className={styles.allCategoriesAdContainer}>
                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP_BELOW}
                        >
                            <Ad
                                id="ark_cat_t0"
                                adType={[AdTypes.AD_728x90]}
                                captionPosition={CaptionPositionTypes.CENTER}
                                keepSize
                            />
                        </Responsive>

                        <Responsive minWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP}>
                            <Ad
                                id="ark_cat_t0"
                                adType={[AdTypes.AD_970x90, AdTypes.AD_728x90]}
                                captionPosition={CaptionPositionTypes.CENTER}
                                keepSize
                            />
                        </Responsive>
                    </div>
                )}

                <div className={classNames(styles.list, styles.allCategoriesList, adFree && styles.freeVersionList)}>
                    {categories.map((category) => {
                        return (
                            <div className={styles.listItem} key={category.name[this.currentLang]}>
                                <CategoryCard
                                    cardType={category.pageName}
                                    categorySlug={category.slug[this.currentLang]}
                                    caption={category.name[this.currentLang]}
                                    currentLang={this.currentLang}
                                    onClick={() => {
                                        // TODO: put here some event track, that analytics team should provide us
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
        return result;
    }
}
