// create a RowGamesSliders component to display the games sliders
//
// Path: src\client\organisms\RowGamesSliders\RowGamesSliders.tsx

import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { CircleArrowIcon } from '../../atoms/Icons/Styleguide/CircleArrowIcon/CircleArrowIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { PageTypes } from '../../constants/Pages';
import { FavoritesAnalyticsClickPageTypes } from '../../features/Favorites/FavoritesAnalyticsAi';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { IGame } from '../../models/Game/Game';
import { CategorySection, GamesListFn } from '../../models/GamesList';
import { PagesData } from '../../models/PagesData';
import { Ad, AdTypes, CaptionPositionTypes } from '../../molecules/Ad/Ad';
import { GameCard, GameCardTypes } from '../../molecules/GameCard/GameCard';
import HomepageSlider from '../../molecules/HomepageSlider/HomepageSlider';
import { Analytics } from '../../services/Analytics/Analytics';
import { GameService } from '../../services/GameService';
import { PageService } from '../../services/PageService';
import { UrlService } from '../../services/UrlService';
import styles from './RowGamesSliders.css';

type RowGamesSlidersProps = {
    adFree: boolean;
};

export const RowGamesSliders = React.memo((props: RowGamesSlidersProps) => {
    const homepageCategoriesListRedesign = useSelector((state) => state.gameLists.homepageCategoriesList);
    const currentLang = useSelector((state) => state.currentLang);
    const pages = useSelector((state) => PagesData.getPages(state.pages));
    const gamesState = useSelector((state) => state.games);
    //const deviceType = useSelector((state) => state.deviceType);
    const games = GameService.gameModelToGame(gamesState);
    const userFavoritesList = useSelector((state) => state.userFavoritesList);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);

    const history = useHistory();

    const gamesFiltered = GameService.filterGamesByLang(games, currentLang);

    const categoriesList: CategorySection[] = homepageCategoriesListRedesign.map((i) => ({
        ...i,
        gamesListFn: ((gamesList: IGame[]) =>
            i.gameSlugs
                .filter((slug) => gamesList.find((game) => game.slug === slug))
                .map((slug) => gamesList.find((game) => game.slug === slug))) as GamesListFn,
    }));

    const getCategorySectionByName = (name: string): CategorySection => {
        return categoriesList.find((s) => s.key.toLowerCase() === name.toLowerCase());
    };

    const getLocalizedLink = (categorySlug: string) => {
        const data = `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${
            PageService.getPageSEOByPageName(pages, categorySlug).slug[currentLang]
        }/`;
        return data;
    };

    const openSeeAll = (categorySlug: string) => {
        const link = getLocalizedLink(categorySlug);
        history.push(link);
    };

    useEffect(() => {
        // for each h2 element set width with children width
        const container = document.getElementById('RowGamesSliders');
        const h2s = container.querySelectorAll('h2');
        h2s.forEach((h2) => {
            const children = h2.querySelectorAll('*');
            let width = 0;
            children.forEach((child) => {
                width += child.getBoundingClientRect().width;
            });
            h2.style.width = `${width}px`;
        });
    }, []);

    return (
        <div className={styles.wrapper} id="RowGamesSliders">
            {getCategorySectionByName('Arkadium fan favorites')
                .gamesListFn(gamesFiltered)
                .filter((game) => !!game).length > 0 && (
                <>
                    <Responsive minWidth={1024}>
                        <div className={classNames(styles.row)}>
                            <div className={styles.rowContent}>
                                {!props.adFree && (
                                    <Ad
                                        id="ark_display_allGames_r1"
                                        hasBackground={false}
                                        captionPosition={CaptionPositionTypes.CENTER}
                                        adType={[AdTypes.AD_970x90]}
                                    />
                                )}
                            </div>

                            <div className={styles.rowContent}>
                                <HomepageSlider
                                    pseudoTile
                                    items={getCategorySectionByName('Arkadium fan favorites').gamesListFn(games)}
                                    gameCardType={GameCardTypes.LARGE}
                                    promoboxCategory="Arkadium Fan Favorites"
                                    promoBoxPosition="1"
                                    caption={getCategorySectionByName('Arkadium fan favorites').name}
                                    headerLink={getLocalizedLink('best')}
                                    variation="C"
                                    doubleSize={true}
                                    onSeeAllClick={() => {
                                        Analytics.trackEvent(
                                            Analytics.categories.seeAllCategoryClick(
                                                getCategorySectionByName('Arkadium fan favorites').name
                                            )
                                        );
                                        openSeeAll('best');
                                    }}
                                />
                            </div>
                        </div>
                    </Responsive>
                    <Responsive maxWidth={1023}>
                        <div className={classNames(styles.row)}>
                            <div className={styles.header}>
                                <I18nText
                                    as={'h2'}
                                    keyName={getCategorySectionByName('Arkadium fan favorites').name}
                                    className={styles.caption}
                                    link={getLocalizedLink('best')}
                                    onClick={() => {
                                        Analytics.trackEvent(
                                            Analytics.categories.seeAllCategoryClick(
                                                getCategorySectionByName('Arkadium fan favorites').name
                                            )
                                        );
                                    }}
                                />
                                <NavLink
                                    className={classNames()}
                                    to={getLocalizedLink('best')}
                                    onClick={() => {
                                        Analytics.trackEvent(
                                            Analytics.categories.seeAllCategoryClick(
                                                getCategorySectionByName('Arkadium fan favorites').name
                                            )
                                        );
                                    }}
                                >
                                    <CircleArrowIcon black width={32} height={32} />
                                </NavLink>
                            </div>

                            <div className={styles.listItems}>
                                {favoritesService
                                    .favoritesListSorter(
                                        getCategorySectionByName('Arkadium fan favorites').gamesListFn(games),
                                        userFavoritesList
                                    )
                                    .map((game, index) => {
                                        if (index >= 4) return <></>;

                                        return (
                                            <div key={game.alias} className={styles.item}>
                                                <GameCard
                                                    game={game}
                                                    gameCardType={GameCardTypes.SMALL}
                                                    isFavorite={game.isFavorite}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.categories.seeAllCategoryClick(
                                                                getCategorySectionByName('Arkadium fan favorites').name
                                                            )
                                                        );
                                                    }}
                                                    dataElementDescription="category-game-card"
                                                    favoritePageType={FavoritesAnalyticsClickPageTypes.CATEGORY}
                                                    variation="C"
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </Responsive>
                </>
            )}

            {categoryPromoData?.categoryName && GameService.getByCategoryGames(games, categoryPromoData.categoryName).length > 0 && (
                <div className={styles.row}>
                    <Responsive minWidth={1024}>
                        <div className={styles.rowContent}>
                            <HomepageSlider
                                items={GameService.getByCategoryGames(games, categoryPromoData.categoryName)}
                                gameCardType={GameCardTypes.LARGE}
                                promoboxCategory={categoryPromoData.categoryName}
                                promoBoxPosition="2"
                                caption="Seasonal Games"
                                onSeeAllClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(categoryPromoData.categoryName)
                                    );
                                    openSeeAll(categoryPromoData.categoryName);
                                }}
                                headerLink={getLocalizedLink(categoryPromoData.categoryName)}
                                pseudoTile
                                variation="C"
                            />
                        </div>
                    </Responsive>
                    <Responsive maxWidth={1023}>
                        <div className={styles.header}>
                            <I18nText
                                as={'h2'}
                                keyName={categoryPromoData.categoryName}
                                className={styles.caption}
                                link={getLocalizedLink(categoryPromoData.categoryName)}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(categoryPromoData.categoryName)
                                    );
                                }}
                            />

                            <NavLink
                                className={classNames()}
                                to={getLocalizedLink(categoryPromoData.categoryName)}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(categoryPromoData.categoryName)
                                    );
                                }}
                            >
                                <CircleArrowIcon black width={32} height={32} />
                            </NavLink>
                        </div>
                        <div className={styles.listItems}>
                            {favoritesService
                                .favoritesListSorter(
                                    GameService.getByCategoryGames(games, categoryPromoData.categoryName),
                                    userFavoritesList
                                )
                                .map((game, index) => {
                                    if (index >= 4) return <></>;
                                    return (
                                        <div key={game.alias} className={styles.item}>
                                            <GameCard
                                                game={game}
                                                gameCardType={GameCardTypes.SMALL}
                                                isFavorite={game.isFavorite}
                                                onClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.categories.seeAllCategoryClick(
                                                            categoryPromoData.categoryName
                                                        )
                                                    );
                                                }}
                                                dataElementDescription="category-game-card"
                                                favoritePageType={FavoritesAnalyticsClickPageTypes.CATEGORY}
                                                variation="C"
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                        <div className={classNames(styles.rowContent, styles.adContainer)}>
                            {!props.adFree && (
                                <Ad
                                    id="ark_display_allGames_r1"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_320x50]}
                                />
                            )}
                        </div>
                    </Responsive>
                </div>
            )}

            {GameService.getNewCategoryGames(games).length > 0 && (
                <div className={styles.row}>
                    <Responsive minWidth={1024}>
                        <div className={styles.rowContent}>
                            {!props.adFree && (
                                <Responsive minWidth={1200}>
                                    <Ad
                                        id="ark_display_allGames_r2"
                                        hasBackground={false}
                                        captionPosition={CaptionPositionTypes.CENTER}
                                        adType={[AdTypes.AD_970x90]}
                                    />
                                </Responsive>
                            )}
                        </div>
                        <div className={styles.rowContent}>
                            <HomepageSlider
                                items={GameService.getNewCategoryGames(games)}
                                gameCardType={GameCardTypes.LARGE}
                                promoboxCategory="New Game Releases"
                                promoBoxPosition="2"
                                caption={getCategorySectionByName('New Game Releases').name}
                                onSeeAllClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(
                                            getCategorySectionByName('New Game Releases').name
                                        )
                                    );
                                    openSeeAll('new');
                                }}
                                headerLink={getLocalizedLink('new')}
                                pseudoTile
                                variation="C"
                                doubleSize={true}
                            />
                        </div>
                    </Responsive>
                    <Responsive maxWidth={1023}>
                        <div className={styles.header}>
                            <I18nText
                                as={'h2'}
                                keyName={getCategorySectionByName('New Game Releases').name}
                                className={styles.caption}
                                link={getLocalizedLink('new')}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(
                                            getCategorySectionByName('New Game Releases').name
                                        )
                                    );
                                }}
                            />
                            <NavLink
                                className={classNames()}
                                to={getLocalizedLink('new')}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.categories.seeAllCategoryClick(
                                            getCategorySectionByName('New Game Releases').name
                                        )
                                    );
                                }}
                            >
                                <CircleArrowIcon black width={32} height={32} />
                            </NavLink>
                        </div>

                        <div className={styles.listItems}>
                            {favoritesService
                                .favoritesListSorter(GameService.getNewCategoryGames(games), userFavoritesList)
                                .map((game, index) => {
                                    if (index >= 4) return <></>;
                                    return (
                                        <div key={game.alias} className={styles.item}>
                                            <GameCard
                                                game={game}
                                                gameCardType={GameCardTypes.SMALL}
                                                isFavorite={game.isFavorite}
                                                onClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.categories.seeAllCategoryClick(
                                                            getCategorySectionByName('New Game Releases').name
                                                        )
                                                    );
                                                }}
                                                dataElementDescription="category-game-card"
                                                favoritePageType={FavoritesAnalyticsClickPageTypes.CATEGORY}
                                                variation="C"
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </Responsive>
                </div>
            )}
        </div>
    );
});
