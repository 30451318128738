import React, { useEffect } from "react";

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import styles from './CategoryTemplate.css';
import CategoryBanner from "./components/CategoryBanner";
import { I18nText } from '../../atoms/i18nText/i18nText';
import { NavigationMenuItem } from '../../constants/Menu';
import { PageTypes } from '../../constants/Pages';
import { IGame } from '../../models/Game/Game';
import { CategoryPageSEO } from '../../models/PagesData';
import { HeaderBackgroundText } from '../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { PageDescription } from "../../molecules/PageDescription/PageDescription";
import { CategoryPageContent } from "../../organisms/CategoryPageContent/CategoryPageContent";
import { CategoryPromo } from '../../organisms/CategoryPromo/CategoryPromo';
import { CategorySideBar } from '../../organisms/CategorySideBar/CategorySideBar';
import { Analytics } from '../../services/Analytics/Analytics';
import { DeviceType } from '../../services/DeviceDetector';
import { PWAService } from '../../services/PWAService';
import { CategoryPromoType } from '../../store/ducks/categoryPromo/categoryPromo';

export type CategoryTemplateProps = {
    games: IGame[];
    categories: CategoryPageSEO[];
    pageType: PageTypes;
    category: CategoryPageSEO;
    categoryName: string;
    sideBarMenuItems: NavigationMenuItem[];
    currentLang: string;
    goToPage: (page: string) => void;
    deviceType: DeviceType;
    categoryPromoData: CategoryPromoType;
    adFree?: boolean;
};

type CategoryTemplateState = {
    showSkeleton: boolean;
    limitedGamesVariation: string;
    seeAllGames: boolean;
    hydrateRerender: number;
};

interface LocationParams {
    search: string;
}

const LimitedGamesListener = React.memo(({ onData }: any) => {
    const location = useLocation<LocationParams>();

    useEffect(() => {
        // Code to execute when query parameters change
        const regex = /action=(\w+)/;
        const match = location.search.match(regex);

        try {
            const action = match != null ? match[1] : '';

            if (action === 'seeallgames') onData(true);
        } catch (error) {
            onData(false);
        }
    }, [location.search]);
    return <React.Fragment />;
});
const PageContent = (props: CategoryTemplateProps & CategoryTemplateState) => {
    const { category, currentLang, games, categories, pageType, deviceType, adFree, seeAllGames } = props;

    return (
        <>
            <div
                className={classNames(styles.tilesContainer, {
                    [styles.bottomPadding]: props.pageType === PageTypes.AllCategories,
                })}
            >
                <div className={styles.expandContent}>
                    <CategoryPageContent
                        games={games}
                        categories={categories}
                        pageType={pageType}
                        currentLanguage={currentLang}
                        deviceType={deviceType}
                        adFree={adFree}
                        variation={props.limitedGamesVariation}
                        seeAllGames={seeAllGames}
                        categoryPageName={category.pageName}
                    />
                </div>
            </div>

            {!props.seeAllGames && (
                <CategoryBanner
                    pageType={pageType}
                    categoryPageName={category.pageName}
                    currentLang={props.currentLang}
                />
            )}

            {props.pageType === PageTypes.Category && (
                <div className={classNames(styles.descriptionContainer)}>
                    <div className={styles.expandContent}>
                        <PageDescription
                            content={[
                                {
                                    key: 'categoryDescriptionTitle',
                                    tabName:
                                        'pageDescriptionTitle' in category
                                            ? category.pageDescriptionTitle[currentLang]
                                            : '',
                                    description: category.onPageDescription[currentLang],
                                    tabCaptionTag: 'h2',
                                },
                            ]}
                            className="categoryPageTab"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export class CategoryTemplate extends React.PureComponent<CategoryTemplateProps, CategoryTemplateState> {
    state = {
        showSkeleton: true,
        limitedGamesVariation: '',
        hydrateRerender: 0,
        seeAllGames: false,
    };

    sidebarRef = React.createRef<HTMLDivElement>();
    contentRef = React.createRef<HTMLDivElement>();

    onSideBarItemClick = (link: string, categorySlug: string) => {
        Analytics.trackEvent(Analytics.categories.categoryLeftNavigationClick(categorySlug));
        this.props.goToPage(link);
    };

    adjustSideBarHeight = () => {
        const sidebarEl = this.sidebarRef.current;
        const contentEl = this.contentRef.current;
        const footerEl = document.getElementById('siteFooter');
        const windowHeight = window.innerHeight;
        const distanceScrolled = window.scrollY;

        if (sidebarEl && footerEl && contentEl) {
            const sidebarOffsetTop = sidebarEl.getBoundingClientRect().top + distanceScrolled;
            const footerHeight = footerEl.clientHeight;
            const contentHeight = contentEl.clientHeight;
            let sideBarHeight = windowHeight - sidebarOffsetTop - footerHeight;

            if (sideBarHeight <= contentHeight) {
                sideBarHeight = contentHeight;
            }

            sidebarEl.style.height = `${sideBarHeight}px`;
        }
    };

    onWindowResize = () => {
        this.adjustSideBarHeight();
    };

    renderPromoBanner = (categoryPromoData: CategoryPromoType): boolean => {
        return Boolean(
            categoryPromoData?.promoButtonTextRegistered &&
            categoryPromoData?.promoButtonTextUnRegistered &&
            categoryPromoData?.promoPopupButtonText &&
            categoryPromoData?.promoPopupText &&
            categoryPromoData?.promoPopupTitle &&
            categoryPromoData?.promoText &&
            categoryPromoData?.promoTextClicked &&
            categoryPromoData?.promoTitle &&
            categoryPromoData?.promoLeftImage &&
            categoryPromoData?.promoBGImage &&
            categoryPromoData?.promoBGImageMobile &&
            categoryPromoData?.promoPopupBG
        );
    }

    componentDidMount() {
        const { category: currentCategory, currentLang } = this.props;

        window.addEventListener('resize', this.onWindowResize);
        PWAService.getUserVisitPWA(currentCategory.slug[currentLang], undefined, false);
        this.setState({ hydrateRerender: 1 });
        setTimeout(() => this.onWindowResize());
    }

    componentDidUpdate() {
        setTimeout(() => this.onWindowResize());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    render() {
        const {
            category,
            categoryName,
            games,
            currentLang,
            pageType,
            deviceType,
            sideBarMenuItems,
            categoryPromoData,
        } = this.props;
        const categorySlugFinal = categoryPromoData?.categorySlug?.replaceAll?.(' ', '-')?.toLowerCase?.()?.trim?.();
        const categoryNameFinal = categoryName.replaceAll?.(' ', '-')?.toLowerCase?.()?.trim?.();
        const isPromoCategory = categorySlugFinal && categoryNameFinal && categorySlugFinal === categoryNameFinal;
        const renderBanner = this.renderPromoBanner(categoryPromoData);

        return (
            <>
                <LimitedGamesListener
                    onData={(e) => {
                        this.setState({ seeAllGames: e });
                    }}
                />

                <div className={styles.pageContainer}>
                    <HeaderBackgroundText
                        currentLang={currentLang}
                        pageData={category}
                        pageType={PageTypes.Category}
                        deviceType={deviceType}
                    />

                    <div className={styles.contentWrapper}>
                        <div
                            className={classNames(styles.sideBarContainer, styles.mobileHidden)}
                            role="region"
                            aria-label="Categories"
                        >
                            <CategorySideBar
                                ref={this.sidebarRef}
                                activeCategorySlug={category.slug[currentLang]}
                                sideBarMenuItems={sideBarMenuItems}
                                pageType={pageType}
                                currentLang={currentLang}
                                games={games}
                                onMenuItemClick={this.onSideBarItemClick}
                            />
                        </div>
                        <div>
                            <main
                                className={styles.pageContentContainer}
                                key={this.state.hydrateRerender}
                                ref={this.contentRef}
                            >
                                {/* CategoryPromo promotion Block start */}
                                {
                                    categoryPromoData && renderBanner &&currentLang === 'en' && isPromoCategory && <CategoryPromo />
                                }
                                {/* CategoryPromo promotion Block end */}

                                {games.length === 0 && pageType === PageTypes.Category ? (
                                    <div className={styles.noGamesContainer}>
                                        <I18nText
                                            className={styles.noGamesText}
                                            as="p"
                                            keyName="NO_GAMES_IN_CATEGORY"
                                        />
                                    </div>
                                ) : (
                                    <PageContent {...this.props} {...this.state} />
                                )}
                            </main>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
