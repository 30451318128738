import React, { FC } from "react";

import { PageTypes } from "../../../constants/Pages";
import { Banner } from "../../../molecules/Banner/Banner";
import { TranslationService } from "../../../services/TranslationService";
import { UrlService } from "../../../services/UrlService";

interface IBannerSeeAll {
  pageType: PageTypes;
  categoryPageName: string;
  currentLang: string;
}

const CategoryBanner: FC<IBannerSeeAll> = (props) => {
  const { pageType, categoryPageName, currentLang } = props;

  if (pageType === PageTypes.AllCategories || categoryPageName !== 'All') {
    return null;
  }

  const url = UrlService.createURL(UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category), false, true, false);
  const propsData = {
    titleTxt: TranslationService.translateIt('ALL_GAMES_PAGE_BANNER_TITLE'),
    subTitleTxt: TranslationService.translateIt('ALL_GAMES_PAGE_BANNER_SUBTITLE'),
    url: `${url}/?action=seeallgames`,
  };

  return <Banner {...propsData}></Banner>
};

export default CategoryBanner;