import React from 'react';

import { Helmet } from 'react-helmet-async';
import { WithTranslation, withTranslation } from 'react-i18next';

import { NavLink } from '../../atoms/Link/Link';
import { UrlService } from '../../services/UrlService';
import styles from './404Template.css';

type P404TemplateBaseProps = Record<string, unknown> & WithTranslation;

export const P404TemplateBase = React.memo((props: P404TemplateBaseProps) => {
    return (
        <>
            <Helmet>
                <title>{props.t('404_PAGE_TITLE')}</title>
            </Helmet>
            <main className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.content}>
                        <section>
                            <h1 className={styles.header}>{props.t('404_WELL_SHOOT')}</h1>
                            <p className={styles.subHeader}>{props.t('404_MAIN_DESCRIPTION')}</p>
                            <p className={styles.text}>{props.t('404_NOT_LOST')}</p>
                            <NavLink to={UrlService.createURL('/')} className={styles.homepageLink}>
                                {props.t('404_HOMEPAGE_LINK')}
                            </NavLink>
                        </section>
                    </div>
                </div>
            </main>
        </>
    );
});

export const P404Template = withTranslation()(P404TemplateBase);
