import React from 'react';

import classNames from 'classnames';

import { GroupStrategy } from './GroupStrategy';
import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { FavoritesAnalyticsClickPageTypes } from '../../../features/Favorites/FavoritesAnalyticsAi';
import { IGame } from '../../../models/Game/Game';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { GameCard, GameCardTypes } from '../../../molecules/GameCard/GameCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { DeviceType } from '../../../services/DeviceDetector';
import styles from '../CategoryPageContent.css';

export class MobileStrategy implements GroupStrategy {
    getParentWidth: () => number;

    cardSize: number;

    cardType: GameCardTypes;

    deviceType: DeviceType;

    constructor(getParentWidth: () => number, deviceType: DeviceType) {
        this.getParentWidth = getParentWidth || (() => 480);
        this.deviceType = deviceType;

        if (deviceType === DeviceType.TABLET) {
            this.cardSize = 164;
            this.cardType = GameCardTypes.MEDIUM;
        } else {
            this.cardSize = 104;
            this.cardType = GameCardTypes.SMALL;
        }
        // if (MiscUtils.isServer) {
        //     this.cardSize = 206;
        //     this.cardType = GameCardTypes.MEDIUM;
        // } else if (window.matchMedia('(max-width: 575px)').matches) {
        //     this.cardSize = 104;
        //     this.cardType = GameCardTypes.SMALL;
        // } else {
        //     this.cardType = GameCardTypes.MEDIUM;
        //     this.cardSize = 164;
        // }
    }

    buildGroups(passedGames: IGame[], adFree: boolean, variation: string, seeAllGames: boolean): React.ReactFragment[] {
        const games = passedGames;
        let N = variation !== 'A' ? (games.length <= 36 ? games.length : 36) : games.length;

        seeAllGames ? (N = games.length) : N;

        const rowsCount = 3;
        const smallPadding = this.deviceType === DeviceType.MOBILE; // window.matchMedia(`(max-width: ${ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}px)`).matches;
        const padding = smallPadding ? 10 : 16;
        const parentWidth = this.getParentWidth() - 20 * 2 + padding;
        const cardSize = this.cardSize + padding;
        const cardsInRow = Math.floor(parentWidth / cardSize);
        const groupSize = cardsInRow * rowsCount;
        let groups: IGame[][] = [];
        const groupsCount = Math.ceil(N / groupSize);

        for (let i = 0; i < groupsCount; ++i) {
            groups.push(games.slice(i * groupSize, i * groupSize + groupSize));
        }

        if (groups.length > 1) {
            const lastGroup = groups[groups.length - 1];

            if (lastGroup.length <= cardsInRow) {
                groups[groups.length - 2] = groups[groups.length - 2].concat(lastGroup);
                groups = groups.slice(0, groups.length - 1);
            }
        }

        return groups.map((group, j) => (
            <>
                {!adFree && j === 0 && this.deviceType === DeviceType.TABLET && (
                    <div
                        className={classNames(styles.adRowMobile, {
                            [styles.firstItem]: j === 0,
                        })}
                    >
                        <div className={styles.adContainer}>
                            <Ad
                                id={`ark_cat_t${j}`}
                                adType={[AdTypes.AD_728x90]}
                                captionPosition={CaptionPositionTypes.CENTER}
                                keepSize
                            />
                        </div>
                    </div>
                )}

                <div className={classNames(styles.list, adFree && styles.freeVersionList)}>
                    {group.map((game, index) => (
                        <div className={styles.listItem} key={game.alias}>
                            <GameCard
                                game={game}
                                gameCardType={this.cardType}
                                isFavorite={game.isFavorite}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.general.promoboxSlotClick(
                                            this.cardType,
                                            'Body',
                                            game,
                                            groupSize * j + index
                                        )
                                    );
                                }}
                                dataElementDescription="category-game-card"
                                favoritePageType={FavoritesAnalyticsClickPageTypes.CATEGORY}
                            />
                        </div>
                    ))}
                </div>
                {!adFree && (
                    <div
                        className={classNames(styles.adRowMobile, {
                            [styles.lastItem]: j === groups.length - 1,
                        })}
                    >
                        <div className={styles.adContainer}>
                            <Responsive
                                minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                                maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
                            >
                                <Ad
                                    id={`ark_cat_t${j + 1}`}
                                    adType={[AdTypes.AD_300x250]}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    keepSize
                                />
                            </Responsive>
                            <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE_BELOW}>
                                <Ad
                                    id={`ark_cat_t${j}`}
                                    adType={[AdTypes.AD_300x250]}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    keepSize
                                />
                            </Responsive>
                        </div>
                    </div>
                )}
            </>
        ));
    }
}
