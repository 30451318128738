export const noIndexPages: string[] = [
    'fr/jeux-gratuits/addictif',
    'fr/jeux-gratuits/arcade',
    'fr/jeux-gratuits/association',
    'fr/jeux-gratuits/jeux-de-réflexion',
    'fr/jeux-gratuits/jeux-de-societe',
    'fr/jeux-gratuits/juex-de-casino',
    'fr/jeux-gratuits/juex-de-mémoire',
    'fr/jeux-gratuits/maths',
    'fr/jeux-gratuits/poker',
    'fr/jeux-gratuits/sport',
    'fr/jeux-gratuits/mots-croises',
    'fr/jeux-gratuits/mot',
    'fr/jeux-gratuits/objets-caches',
    'fr/jeux-gratuits/course',
    'fr/jeux-gratuits/juex-dorthographe',
    'fr/jeux-gratuits/game-shows',
    'fr/jeux-gratuits/puzzle',
    'fr/jeux-gratuits/stan-newmans-crossword',
];
