import React from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';

import { IGame } from '../../models/Game/Game';
import { Breadcrumb } from './Breadcrumb';
import styles from './Breadcrumbs.css';

export interface BreadcrumbItem {
    to: string;
    label: string;
    position: number;
    game?: IGame;
    onLightBg?: boolean;
}

export interface BreadcrumbsBaseProps {
    items: BreadcrumbItem[];
    pathName: string;
    className: string;
    onLightBg?: boolean;
    isSSRLink?: boolean;
}

const BreadcrumbsBase = React.memo((props: BreadcrumbsBaseProps) => {
    return (
        <BreadcrumbsBlock
            itemScope
            itemType="http://schema.org/BreadcrumbList"
            className={classNames(props.className, props.onLightBg && styles.onLightBg)}
            data-element-description="breadcrumbs"
        >
            {props.items.map((item) => {
                return (
                    <Breadcrumb
                        key={item.label}
                        title={`Go to ${item.label}`}
                        href={item.to}
                        name={item.label}
                        position={item.position.toString()}
                        last={props.items.length === item.position}
                        game={item.game}
                        onLightBg={props.onLightBg}
                        isSSRLink={props.isSSRLink}
                    />
                );
            })}
        </BreadcrumbsBlock>
    );
});

BreadcrumbsBase.displayName = 'Breadcrumbs';

export const Breadcrumbs = connect((state) => ({
    pathName: state.router.location.pathname,
}))(BreadcrumbsBase);

const BreadcrumbsBlock = ({ className, ...props }: any) => (
    <div className={classNames(styles.breadcrumbsBlock, className)} {...props} />
);
