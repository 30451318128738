import React from 'react';

import classNames from 'classnames';

import { GroupStrategy } from './GroupStrategy';
import { MiscUtils } from '../../../../utils/MiscUtils';
import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { FavoritesAnalyticsClickPageTypes } from '../../../features/Favorites/FavoritesAnalyticsAi';
import { IGame } from '../../../models/Game/Game';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { GameCard, GameCardTypes } from '../../../molecules/GameCard/GameCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { DeviceType } from '../../../services/DeviceDetector';
import styles from '../CategoryPageContent.css';

export class DesktopStrategy implements GroupStrategy {
    getParentWidth: () => number;

    cardSize: number;

    constructor(getParentWidth: () => number, deviceType: DeviceType) {
        this.getParentWidth = getParentWidth;

        if (deviceType === DeviceType.DESKTOP) {
            this.cardSize = 230;
        } else {
            this.cardSize = 206;
        }
        // if (MiscUtils.isServer) {
        //     this.cardSize = 206;
        // } else if (window.matchMedia('(min-width: 1200px)').matches) {
        //     this.cardSize = 230;
        // } else {
        //     this.cardSize = 206;
        // }
    }

    buildGroups(gamesPassed: IGame[], adFree: boolean, variation: string, seeAllGames: boolean): React.ReactFragment[] {
        const games =gamesPassed;
        let N = variation !== 'A' ? (games.length <= 36 ? games.length : 36) : games.length;

        seeAllGames ? (N = games.length) : N;

        const rowsCount = 3;
        const parentWidth = this.getParentWidth() - 28 * 2 + 16;
        const cardSize = this.cardSize + 16;
        const cardsInRow = Math.floor(parentWidth / cardSize);
        const groupSize = cardsInRow * rowsCount;
        let groups: IGame[][] = [];
        const groupsCount = Math.ceil(N / groupSize);

        for (let i = 0; i < groupsCount; ++i) {
            groups.push(games.slice(i * groupSize, i * groupSize + groupSize));
        }

        if (groups.length > 1) {
            const lastGroup = groups[groups.length - 1];

            if (lastGroup.length <= cardsInRow) {
                groups[groups.length - 2] = groups[groups.length - 2].concat(lastGroup);
                groups = groups.slice(0, groups.length - 1);
            }
        }

        const isBiggerScreen = !MiscUtils.isServer && window.matchMedia('(min-width: 1200px)').matches;

        return groups.map((group, j) => (
            <>
                {!adFree && (
                    <div
                        className={classNames(styles.adRowDesktop, {
                            [styles.firstItem]: j === 0,
                        })}
                    >
                        <div className={styles.adContainer}>
                            <Responsive
                                minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}
                                maxWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP_BELOW}
                            >
                                <Ad
                                    id={`ark_cat_t${j}`}
                                    adType={[AdTypes.AD_728x90]}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    keepSize
                                />
                            </Responsive>

                            <Responsive minWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP}>
                                <Ad
                                    id={`ark_cat_t${j}`}
                                    adType={[AdTypes.AD_970x90, AdTypes.AD_728x90]}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    keepSize
                                />
                            </Responsive>
                        </div>
                    </div>
                )}
                <div className={classNames(styles.list, adFree && styles.freeVersionList)}>
                    {group.map((game, index) => (
                        <div className={styles.listItem} key={game.alias}>
                            <GameCard
                                game={game}
                                gameCardType={
                                    isBiggerScreen || adFree ? GameCardTypes.MEDIUM_PLUS : GameCardTypes.MEDIUM
                                }
                                isFavorite={game.isFavorite}
                                onClick={() => {
                                    Analytics.trackEvent(
                                        Analytics.general.promoboxSlotClick(
                                            isBiggerScreen || adFree ? GameCardTypes.MEDIUM_PLUS : GameCardTypes.MEDIUM,
                                            'Body',
                                            game,
                                            groupSize * j + index
                                        )
                                    );
                                }}
                                dataElementDescription="category-game-card"
                                favoritePageType={FavoritesAnalyticsClickPageTypes.CATEGORY}
                            />
                        </div>
                    ))}
                </div>
            </>
        ));
    }
}
