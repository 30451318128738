import React from 'react';

import classNames from 'classnames';

import { I18nText } from '../../i18nText/i18nText';
import styles from './TextButton.css';

type TextButtonTags = 'a' | 'button';

type TextButtonProps = {
    caption: string;
    as?: TextButtonTags;
    href?: string;
    textClassName?: string;
    buttonClassName?: string;
    onClick?: any;
};

export const TextButton = React.memo((props: TextButtonProps) => {
    const Component = props.as || 'button';
    const optionalProps: any = {};
    if (props.href) {
        optionalProps.href = props.href;
    }

    return (
        <Component
            className={classNames(styles.textButton, props.buttonClassName)}
            onClick={props.onClick}
            {...optionalProps}
        >
            <I18nText keyName={props.caption} className={classNames(styles.caption, props.textClassName)} />
        </Component>
    );
});
