import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { NavLink } from '../../atoms/Link/Link';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import styles from './Breadcrumbs.css';

interface BreadcrumbProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    version?: number;
    href?: string;
    name?: string;
    position?: string;
    last?: boolean;
    game?: IGame;
    onLightBg?: boolean;
    isSSRLink?: boolean;
}

const BreadcrumbBase = ({ last, title, href, name, position, game, onLightBg, isSSRLink }: BreadcrumbProps) => {
    return (
        <>
            <span itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                {(() => {
                    if (!last) {
                        return (
                            <NavLink
                                isSSRLink={isSSRLink}
                                itemProp="item"
                                title={title}
                                to={href}
                                onClick={() => {
                                    Analytics.trackEvent(Analytics.general.breadcrumbClick(game));
                                }}
                            >
                                <I18nText
                                    itemProp="name"
                                    keyName={name === 'Originals' ? 'Original Games by Arkadium' : name}
                                />
                            </NavLink>
                        );
                    }
                    return <I18nText itemProp="name" keyName={name} />;
                })()}
                <meta itemProp="position" content={position} />
            </span>
            <div className={classNames(styles.breadcrumbSeparator, onLightBg && styles.separatorOnLight)}>|</div>
        </>
    );
};
BreadcrumbBase.displayName = 'Breadcrumb';

export const Breadcrumb = connect((state) => ({
    pathname: state.router.location.pathname,
}))(BreadcrumbBase);
