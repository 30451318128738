import React from "react";

import { push } from "connected-react-router";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { MiscUtils } from "../../utils/MiscUtils";
import { StringUtils } from "../../utils/StringUtils";
import { ConnectedHelmet } from "../atoms/ConnectedHelmet";
import { ErrorBoundary } from "../atoms/ErrorBoundary/ErrorBoundary";
import { StructuredDataList, StructuredDataWebPage, StructuredDataWebSite } from "../atoms/StructuredData";
import { environment } from "../config/environment";
import { NavigationMenuItem } from "../constants/Menu";
import { PageName, PageTypes } from "../constants/Pages";
import { IGame } from "../models/Game/Game";
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from "../models/PagesData";
import adsService from "../services/AdsService";
import { Analytics } from "../services/Analytics/Analytics";
import { DeviceType } from "../services/DeviceDetector";
import { GameService } from "../services/GameService";
import { MetaInfoService } from "../services/MetaInfoService";
import { PageService } from "../services/PageService";
import { PwaManifestConfig, PWAService } from "../services/PWAService";
import { UrlService } from "../services/UrlService";
import { CategoryPromoType } from "../store/ducks/categoryPromo/categoryPromo";
import { setCategoryPageName } from "../store/ducks/layout";
import { setPageType } from "../store/ducks/pages";
import { CategoryTemplate } from "../templates/Category/CategoryTemplate";
import { P404Template } from "../templates/p404/404Template";
import IPageMeta from "../types/pageMeta";

type CategoryPageProps = {
  games: IGame[];
  pages: CategoryPageSEO[];
  category: PageName;
  url: string;
  dispatch?: any;
  location: any;
  route: any;
  currentLang: string;
  urlSearch: string;
  pageNotFound: boolean;
  pageType: PageTypes;
  deviceType: DeviceType;
  adFree: boolean;
  subscription: boolean;
  categoryPromoData: CategoryPromoType;
};

type CategoryPageState = {
  games: IGame[];
  categorySEO: CategoryPageSEO;
  sideBarMenuItems: NavigationMenuItem[];
  categoryNotFound: boolean;
  addonLocales: any[];
  categoryPages: any[];
};

class CategoryPageBase extends React.PureComponent<CategoryPageProps, CategoryPageState> {
  state = {
    games: [],
    categorySEO: {} as any,
    sideBarMenuItems: [],
    categoryNotFound: false,
    addonLocales: [],
    categoryPages: [],
  };

  private pwaConfig: PwaManifestConfig = {
    name: 'Arkadium Games',
    shortName: 'Arkadium',
    icon192Url: `${UrlService.toCDNUrl('/icons/pwa/icon-192.png')}`,
    icon512Url: `${UrlService.toCDNUrl('/icons/pwa/icon-512.png')}`,
    startUrl: UrlService.createURL(UrlService.cleanupPathname(this.props.location.pathname)),
  };

  private disabledCategories = ['home', 'sports'];

  timerId: any = -1;

  constructor(props) {
    super(props);
    this.init(true);
  }

  modifyState(s: CategoryPageState, forceState: boolean): Promise<void> {
    if (MiscUtils.isServer || forceState) {
      this.state = s;
      return Promise.resolve();
    }

    return new Promise((res) => this.setState(s, res));
  }

  init(forceState: boolean): Promise<any> {
    const { games, pages, category, currentLang } = this.props;
    let newState: any = {};
    let categorySEO;

    if (category) {
      categorySEO = PageService.getPageSEOByPageName(
        pages.filter((p) => (p as CategoryPageSEO).category),
        category
      ) as CategoryPageSEO;
    }

    let categoryNotFound;
    // Check whether category exist

    if (!categorySEO) {
      categoryNotFound = true;
    } else {
      const tempGames = this.matchGames(games, category, categorySEO.name[currentLang]);
      const sideBarMenuItems = PageService.getTopLevelMenuItems(pages, currentLang, games).filter(
        (m) => !StringUtils.equalIgnoreCase(m.pageName, 'Home' as PageName)
      );
      const categoryPages = this.matchCategories(pages, currentLang, games).filter(
        (m) => this.disabledCategories.indexOf(m.pageName.toLowerCase()) === -1
      );
      // setup supported locales for meta
      const addonLocales = PageService.getNonEnLocales(categorySEO);

      newState = {
        games: tempGames,
        sideBarMenuItems,
        addonLocales,
        categoryPages,
      };
    }

    newState = {
      ...newState,
      categorySEO,
      categoryNotFound,
    };

    return this.modifyState(newState, forceState);
  }

  set404Status = () => {
    // this.props.dispatch(set404Status(true));
    this.props.dispatch(setPageType(PageTypes.NotFound));
  };

  goToPage = (page: string) => {
    this.props.dispatch(push(page));
  };

  sortGames(games: IGame[]): IGame[] {
    let gamesSorted: IGame[];

    if (this.props.deviceType === DeviceType.DESKTOP) {
      gamesSorted = games.sort((a, b) => (a.desktopRank > b.desktopRank ? 1 : -1));
    } else if (this.props.deviceType === DeviceType.MOBILE) {
      gamesSorted = games.sort((a, b) => (a.mobileRank > b.mobileRank ? 1 : -1));
    } else if (this.props.deviceType === DeviceType.TABLET) {
      gamesSorted = games.sort((a, b) => (a.tabletRank > b.tabletRank ? 1 : -1));
    } else {
      gamesSorted = games.sort((a, b) => (a.desktopRank > b.desktopRank ? 1 : -1));
    }

    return gamesSorted;
  }

  matchGames(games: IGame[], category: PageName, categoryName: string): IGame[] {
    if (!category) {
      return [];
    }

    if (StringUtils.equalIgnoreCase(category, 'All')) {
      return this.sortGames(games);
    }

    return this.sortGames(
      games.filter((game) =>
        game.categories.find((gameCategory) => StringUtils.equalIgnoreCase(gameCategory, categoryName))
      )
    );
  }

  matchCategories(
    categories: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    language: string,
    games: IGame[]
  ): CategoryPageSEO[] {
    const bag = categories
      .filter((c) => (c as CategoryPageSEO).category)
      .filter((c) => !StringUtils.equalIgnoreCase(c.pageName, 'AllCategories')) as CategoryPageSEO[];
    let result = [];

    bag.forEach((cat) => {
      const categoryGames = games.filter((game) =>
        game.categories.find((gameCategory) => StringUtils.equalIgnoreCase(gameCategory, cat.name[language]))
      );

      if (GameService.filterGamesByLang(categoryGames, language).length > 0 && cat.name[language]) {
        result.push(cat);
      }
    });

    result = result.sort((p1, p2) => p1.order - p2.order);
    return result;
  }

  componentDidMount() {
    // TODO: find a better way to handle not found page
    if (!this.props.pageNotFound && this.state?.categorySEO?.pageName) {
      Analytics.trackEvent(Analytics.general.arenaPromoBox(this.state.sideBarMenuItems, this.props.category));
      this.props.dispatch(setPageType(this.props.route?.pageType));
      this.props.dispatch(setCategoryPageName(this.state.categorySEO.pageName as PageName));
    } else if (!this.props.pageNotFound) {
      return this.set404Status();
    } else {
      return this.render();
    }
  }

  componentDidUpdate(prevProps: CategoryPageProps) {
    if (!this.state?.categorySEO?.pageName) {
      return this.set404Status();
    }

    if (this.props.pageNotFound) {
      return this.render();
    }

    if (this.props.category !== prevProps.category) {
      this.init(false).then(() => {
        this.props.dispatch(setCategoryPageName(this.state?.categorySEO?.pageName as PageName));
      });

      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        adsService.forceRefresh();
      }, 300);
    }

    if (this.props.pageType !== prevProps.pageType) {
      this.init(false).then(() => {
        this.props.dispatch(setPageType(this.props.route.pageType));
      });
    }
  }

  render() {
    const { url, currentLang, deviceType, adFree, subscription, categoryPromoData, category, pageType } =
      this.props;
    const seo = this.state.categorySEO;
    const adFreeValue = adFree || subscription;

    // Check whether category exist
    if (this.state.categoryNotFound || this.props.pageNotFound) {
      if (!this.props.pageNotFound) {
        this.set404Status();
      }

      return <P404Template />;
    }

    const pageMeta: IPageMeta = {
      name: seo.metaHTMLTitle[currentLang],
      url,
      description: seo.metaHTMLDescription[currentLang],
      image: `${UrlService.toCDNUrl('/images/arkadium_logo_medium_500.png')}`,
      imageWidth: 500,
      imageHeight: 173,
      datePublished: '2018-07-17T08:13:19+00:00',
      dateModified: '2019-09-05T12:22:23+00:00',
    };
    let enPathname;
    let localesPath;

    if (pageType === PageTypes.Category) {
      enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.Category)}/${seo.slug.en}/`;
      localesPath = this.state.addonLocales.map((item) => ({
        lang: item,
        path: `/${UrlService.getPageLocalizedRoute(item, PageTypes.Category)}/${seo.slug[item]}/`,
      }));
    } else {
      enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.AllCategories)}/`;
      localesPath = this.state.addonLocales.map((item) => ({
        lang: item,
        path: `/${UrlService.getPageLocalizedRoute(item, PageTypes.AllCategories)}/`,
      }));
    }

    const getLinksInfo = [...MetaInfoService.getLinksInfo(enPathname, localesPath)];

    return (
      <>
        <ConnectedHelmet
          meta={[
            ...MetaInfoService.getPageMetaInfo(seo, currentLang, url),
            {
              name: 'apple-mobile-web-app-title',
              content: 'Arkadium',
            },
            {
              name: 'description',
              content: seo.metaHTMLDescription[currentLang],
            },
          ]}
          title={seo.metaHTMLTitle[currentLang]}
          link={[
            ...getLinksInfo,
            {
              rel: 'apple-touch-icon',
              href: UrlService.toCDNUrl('/icons/pwa/icon-180.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: UrlService.toCDNUrl('/icons/pwa/icon-180.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '167x167',
              href: UrlService.toCDNUrl('/icons/pwa/icon-167.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '152x152',
              href: UrlService.toCDNUrl('/icons/pwa/icon-152.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '120x120',
              href: UrlService.toCDNUrl('/icons/pwa/icon-120.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '76x76',
              href: UrlService.toCDNUrl('/icons/pwa/icon-76.png'),
            },
            { rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` },
            PWAService.manifestLinkObject({ config: this.pwaConfig }),
          ]}
        ></ConnectedHelmet>
        <ErrorBoundary>
          <CategoryTemplate
            games={this.state.games}
            categories={this.state.categoryPages}
            pageType={pageType}
            sideBarMenuItems={this.state.sideBarMenuItems}
            category={seo}
            categoryName={category}
            currentLang={currentLang}
            deviceType={deviceType}
            goToPage={this.goToPage}
            adFree={adFreeValue}
            categoryPromoData={categoryPromoData}
          />
          <StructuredDataWebPage pageMeta={pageMeta} />
          <StructuredDataWebSite />
          <StructuredDataList games={this.state.games} pageMeta={pageMeta} />
        </ErrorBoundary>
      </>
    );
  }
}

export default connect((state, props: RouteComponentProps<any>) => ({
  games: GameService.gameModelToGame(state.games),
  pages: PagesData.getOnlyCategoriesPages(state.pages),
  user: state.user,
  category: PageService.getPageNameBySlug(
    PagesData.getPages(state.pages),
    state.currentLang,
    props.match.params.category,
    state.pageType,
    true
  ),
  url: props.location.pathname,
  location: state.router.location,
  currentLang: state.currentLang,
  urlSearch: props.location.search,
  pageNotFound: state.pageType === PageTypes.NotFound,
  pageType: state.pageType,
  deviceType: state.deviceType,
  adFree: state.preLoadData.adFree,
  subscription: state.preLoadData.subscription,
  categoryPromoData: state.categoryPromoData,
}))(CategoryPageBase);
