import React from 'react';

import classNames from 'classnames';

import styles from './CircleArrowIcon.css';

type iconProps = {
    className?: string;
    leftOriented?: boolean;
    black?: boolean;
    width?: number;
    height?: number;
};

export const CircleArrowIcon = React.memo((props: iconProps) => {
    return (
        <div
            className={classNames({
                [styles.leftOriented]: props.leftOriented,
            })}
        >
            <svg
                className={props.className}
                xmlns="http://www.w3.org/2000/svg"
                width={props.width || 56}
                height={props.height || 56}
                viewBox="0 0 56 56"
                fill="none"
            >
                <path
                    d="M28.1475 38.4063L38.1475 28.4063M38.1475 28.4063L28.1475 18.4063M38.1475 28.4063H18.1475M53.1475 28.4063C53.1475 42.2134 41.9546 53.4063 28.1475 53.4063C14.3403 53.4063 3.14746 42.2134 3.14746 28.4063C3.14746 14.5991 14.3403 3.40625 28.1475 3.40625C41.9546 3.40625 53.1475 14.5991 53.1475 28.4063Z"
                    stroke={props.black ? '#231f20' : '#FCFCFC'}
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
});
